import { createRouter, createWebHistory } from 'vue-router';
import routes from '~pages';
import SignInVue from '@/pages/signin.vue';
import SettingsVue from '@/pages/settings.vue';
import MyAccountVue from '@/pages/profile/index.vue';
import PageNotFound from '@/pages/error/not-found.vue';
import PageUnauthorized from '@/pages/error/unauthorized.vue';
import dayjs from 'dayjs';

// Whitelist pages that does not require authentication
const whitelistPages = [
	ROUTE.INDEX.NAME,
	ROUTE.SIGN_IN.NAME,
	ROUTE.SIGN_UP.NAME,
	ROUTE.USER.NAME,
	ROUTE.EVENT_VIEW.NAME,
	ROUTE.HELP.NAME,
	ROUTE.PRICING.NAME,
	ROUTE.PRICING_CALCULATOR.NAME,
	ROUTE.ERROR_400.NAME,
	ROUTE.ERROR_401.NAME,
	ROUTE.TERMS.NAME,
	ROUTE.PRIVACY_POLICY.NAME,
	ROUTE.CANCELLATION_POLICY.NAME,
	ROUTE.COOKIE_POLICY.NAME,
	ROUTE.COMMUNITY_GUIDELINES.NAME,
	ROUTE.ABOUT_US.NAME,
];

const protectedByRecaptchaPages = [
	ROUTE.EVENT_CREATE.NAME,
];

// Set page that has sticky action bar
const pagesWithActionBar = [
	ROUTE.EVENT_EDIT.NAME,
	ROUTE.EVENT_PREVIEW.NAME,
];

// Set page that need to show pictorial marks logo
const pictorialMarksLogoPages = [
	ROUTE.EVENT_VIEW.NAME,
	ROUTE.EVENT_PREVIEW.NAME,
];

const router = createRouter({
	history: createWebHistory(import.meta.env.VITE_SUBDOMAIN),
	routes: [
		...routes,
		{
			name: ROUTE.SIGN_UP.NAME,
			path: ROUTE.SIGN_UP.PATH,
			component: SignInVue,
			props: { type: AUTH_FORM.SIGN_UP },
		},
		{
			name: ROUTE.PROFILE_ATTENDING.NAME,
			path: ROUTE.PROFILE_ATTENDING.PATH,
			component: MyAccountVue,
			props: { tab: MY_ACCOUNT_TABS.ATTENDING },
		},
		{
			name: ROUTE.PROFILE_HOSTING.NAME,
			path: ROUTE.PROFILE_HOSTING.PATH,
			component: MyAccountVue,
			props: { tab: MY_ACCOUNT_TABS.HOSTING },
		},
		{
			name: ROUTE.SETTINGS_PAYOUTS.NAME,
			path: ROUTE.SETTINGS_PAYOUTS.PATH,
			component: SettingsVue,
			props: { initialTab: SETTING_TABS.PAYOUTS },
		},
		{
			name: ROUTE.SETTINGS_SAVED_CARD.NAME,
			path: ROUTE.SETTINGS_SAVED_CARD.PATH,
			component: SettingsVue,
			props: { initialTab: SETTING_TABS.SAVED_CARDS },
		},
		{
			name: ROUTE.ERROR_400.NAME,
			path: ROUTE.ERROR_400.PATH,
			component: PageNotFound,
		},
		{
			name: ROUTE.ERROR_401.NAME,
			path: ROUTE.ERROR_401.PATH,
			component: PageUnauthorized,
		},
	],
	scrollBehavior(to, _from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		}
		if (to.hash) {
			return { el: to.hash, top: 80 }; // 80px above the element. `80` is height of header
		}
		return { top: 0 };
	},
});

router.beforeEach((to, _from, next) => {
	const authStore = useAuthStore();
	const layoutStore = useLayoutStore();

	const isLoggedIn = authStore.isLoggedIn;
	const isDisableGeoLocationMode = import.meta.env.VITE_DISABLE_GEO_LOCATION === 'true';
	// Check if user's local timezone is in USA
	const localTimezone = dayjs.tz.guess();
	const isUsaTimezone = localTimezone.includes('America');
	const isAllowedToBrowseIn = isLoggedIn || isDisableGeoLocationMode || isUsaTimezone;

	// Get the page title from the route's 'name' attribute (if it exists)
	if (typeof to.name === 'string') {
		const title = getRouteTitleByName(to.name);
		// If the route has a title, set it as the page title of the document/page
		if (title) {
			document.title = title;
		}
	}

	if (!isAllowedToBrowseIn) {
		openLink(import.meta.env.VITE_LEGACY_PEATIX_URL, '_self');
		return;
	}

	layoutStore.isGeolocationChecked = true;
	layoutStore.isPublic = false; // Reset flag to default
	layoutStore.isFullScreen = false; // Reset flag to default

	// Set page's log if it should show pictorial marks logo or combination marks  logo
	if (pictorialMarksLogoPages.includes(to.name as string)) {
		layoutStore.isPublic = true;
	}

	// Hide reCAPTCHA badges if page is not use reCAPTCHA
	if (protectedByRecaptchaPages.includes(to.name as string)) {
		window.document.body.classList.add('protect-by-recaptcha');
	} else {
		window.document.body.classList.remove('protect-by-recaptcha');
	}

	const isWhiteListPath = whitelistPages.includes(to.name as string);
	if (!authStore.isLoggedIn && !isWhiteListPath) {
		// If sign out from staging or prod, it should redirect to `{{domain}}/__cc.cgi`
		if (to.path === ROUTE.SIGN_OUT.PATH && import.meta.env.VITE_ENV !== ENVIRONMENT.DEVELOPMENT) {
			const redirectSignoutUrl = `${import.meta.env.VITE_LEGACY_PEATIX_URL}/__cc.cgi`;
			openLinkInCurrentTab(redirectSignoutUrl);
		} else {
			next({
				name: ROUTE.SIGN_IN.NAME,
				...(to.name === ROUTE.EVENT_CREATE.NAME && {
					query: {
						redirect: ROUTE.EVENT_CREATE.NAME,
					},
				}),
			});
		}
	} else if ((authStore.isLoggedIn && (to.name === ROUTE.SIGN_IN.NAME || to.name === ROUTE.SIGN_UP.NAME))) {
		// Prevent user to access sign in & sign up page from URL if they already sign in.
		next({
			path: '',
			replace: true,
		});
	} else if (authStore.isLoggedIn && to.name === ROUTE.INDEX.NAME) {
		// Navigate logged in user to user's profile instead of landing page
		next({
			name: ROUTE.PROFILE_HOSTING.NAME,
			replace: true,
		});
	} else {
		next();
	}

	if (to.name && pagesWithActionBar.includes(to.name.toString())) {
		layoutStore.hasActionBar = true;
	} else {
		layoutStore.hasActionBar = false;
	}
});

export default router;
