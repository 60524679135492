<script setup lang="ts">
import type { Follower, Social } from '@/types/profile';
import FacebookIcon from '@/components/icons/FacebookIcon.vue';
import XIcon from '@/components/icons/XIcon.vue';
import InstagramIcon from '@/components/icons/InstagramIcon.vue';
import TiktokIcon from '@/components/icons/TiktokIcon.vue';
import YoutubeIcon from '@/components/icons/YoutubeIcon.vue';
import LinkedInIcon from '@/components/icons/LinkedInIcon.vue';
import GlobeIcon from '@/components/icons/GlobeIcon.vue';

interface SocialIcon {
	[key: string]: any
}

defineProps({
	followers: {
		type: Array as PropType<Array<Follower>>,
		default: () => [],
		required: true,
	},
	followerNumber: {
		type: Number,
		default: null,
		required: true,
	},
});

const profileStore = useProfileStore();

const socialIcons: SocialIcon = {
	facebook: FacebookIcon,
	x: XIcon,
	instagram: InstagramIcon,
	tiktok: TiktokIcon,
	youtube: YoutubeIcon,
	linkedin: LinkedInIcon,
	website: GlobeIcon,
};

function getFullUrl(link: Social) {
	if (!link) {
		return '';
	}
	return link.sns === SOCIAL_NAME.WEBSITE ? link.profileUrl : `https://www.${link.profileUrl}`;
}

function handleNavigation(link: Social) {
	const url = getFullUrl(link);
	openLink(url);
}
</script>

<template>
  <div
    v-if="profileStore.socialLink?.length"
    class="social-list-container"
  >
    <BaseButton
      v-for="(link, index) in profileStore.socialLink"
      :key="index"
      class="icon-link"
      variant="link"
      color="gray"
      @click="handleNavigation(link)"
    >
      <component
        :is="socialIcons[link.sns] ?? GlobeIcon"
        width="20"
        height="20"
      />
    </BaseButton>
  </div>
</template>

<style lang="scss" scoped>
.social-list-container {
	display: flex;
	justify-content: center;
	gap: spacings-get(4);

	.icon-link {
		padding: 0;
	}
}
</style>
