<script setup lang="ts">
const LIMIT_SCROLL_HEIGHT_DESKTOP = 520; // Landing's page banner height(600) - header's height(80)
const LIMIT_SCROLL_HEIGHT_MOBILE = 420; // Landing's page banner height(500) - header's height(80)

const layoutStore = useLayoutStore();
const { isMobile } = useWindowResize();
const profileStore = useProfileStore();
const route = useRoute();

const isScrolling = ref(false);
const showConfirmSwitchUiDialog = ref(false);

const isAccessible = computed(() => layoutStore.isGeolocationChecked);
const isHomePage = computed(() => route.name === ROUTE.INDEX.NAME);
const profileCountry = computed(() => profileStore.profile?.countryId);

const isInformationPage = computed(() => {
	const pages = [
		ROUTE.INDEX.NAME,
		ROUTE.PRICING.NAME,
		ROUTE.ABOUT_US.NAME,
	];
	return pages.includes(route.name?.toString() || '');
});

const isShowHeaderInTransparentBackground = computed(() => isHomePage.value && !isScrolling.value);
const isShowFullScreen = computed(() => layoutStore.isFullScreen);

function handleScroll() {
	if (!window.top) {
		return;
	}

	const homePageLimitHeight = isMobile.value ? LIMIT_SCROLL_HEIGHT_MOBILE : LIMIT_SCROLL_HEIGHT_DESKTOP;
	const defaultLimitHeight = 1;
	const limitHeight = isHomePage.value ? homePageLimitHeight : defaultLimitHeight;

	if (window.top.scrollY > limitHeight && !isScrolling.value) {
		isScrolling.value = true;
	} else if (window.top.scrollY < limitHeight && isScrolling.value) {
		isScrolling.value = false;
	}
}

async function handleConfirmSwitchUI() {
	if (profileStore.profileId) {
		// Navigate to old peatix without sign out
		switchToPeatix();
	}
}

watch(profileCountry, async (countryId) => {
	if (countryId && countryId !== DEFAULT_COUNTRY && profileStore.profileId) {
		showConfirmSwitchUiDialog.value = true;
	}
});

onMounted(() => {
	window.addEventListener('scroll', handleScroll);
});
</script>
<template>
  <div
    v-if="isAccessible"
    :class="{'page-action-bar' : layoutStore.hasActionBar }"
  >
    <header
      v-if="!isShowFullScreen"
      :class="{
        'home': isHomePage,
        'transparent': isShowHeaderInTransparentBackground,
        'scrolling': isScrolling }"
    >
      <RouterLink :to="ROUTE.INDEX.PATH">
        <Logo :is-transparent-background="isShowHeaderInTransparentBackground" />
      </RouterLink>
      <Menu :is-transparent-background="isShowHeaderInTransparentBackground" />
    </header>
    <main :class="{'information' : isInformationPage}">
      <BaseNotificationBox />
      <RouterView />
    </main>
    <footer v-if="!isShowFullScreen">
      <FooterLink />
      <span class="copyright">© 2024 Peatix</span>
    </footer>
    <ConfirmDialog
      :is-show="showConfirmSwitchUiDialog"
      confirm-button-name="Ok, I got it"
      hide-cancel-button
      hide-close-icon
      @on-confirm="handleConfirmSwitchUI"
    >
      <template #header>
        <AlertCircleIcon
          class="icon-header-confirm-dlg icon-info"
          :color="ICON_COLOR.PRIMARY"
        />
      </template>
      <template #title>
        Update your country setting
      </template>
      <template #content>
        <div class="content">
          <p class="text-sm text-regular">
            To view this page, please update your country setting in your account settings to the United States.
          </p>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<style lang="scss" scoped>
$headerHeight: rem(80);
$footerHeightDesktop: rem(120);
$footerHeightMobile: rem(288);

.page-action-bar {
  // Add margin bottom if page has action bar
  margin-bottom: rem(62);

  @include media-query-max(mobile) {
    margin-bottom: rem(102);
  }
}

header, footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

header {
  position: sticky;
  top: 0;
  padding: spacings-get(3) spacings-get(8);
  height: $headerHeight;
  background-color: colors-get(base, white);
  z-index: 3;

  // Transition
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;

  &.home {
    position: fixed;
    width: 100%;
  }

  &.transparent {
    background-color: transparent;
  }

  &.scrolling {
    box-shadow: 0px 1px 1px 0px rgba(16, 24, 40, 0.05);
  }
}

footer {
  padding: 0 spacings-get(8);
  height: $footerHeightDesktop;
}

main {
  min-height: calc(100vh - ($headerHeight + $footerHeightDesktop)); // header + footer
  margin: auto;

  &:not(.information) {
    max-width: 1440px;
    padding: 0 spacings-get(4);

    @include media-query-max(mobile) {
      padding: 0;
    }
  }

  @include media-query-max(tablet) {
    min-height: calc(100vh - ($headerHeight + $footerHeightMobile)); // header + footer
  }
}

footer {
  .copyright {
    @include fonts-get(semibold, text-md);
    color: colors-get(gray, 500);
  }
}

@include media-query-max(tablet) {
  header {
    padding: spacings-get(3) spacings-get(4);
  }

  footer {
    flex-direction: column;
    align-items: center;
    row-gap: rem(18);
    padding: spacings-get(12) 0;
    height: auto;
  }
}
</style>
