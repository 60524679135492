<script setup lang="ts">
import type { DropdownOption } from '@/types/dropdown';
import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';
import ChevronUpIcon from '@/components/icons/ChevronUpIcon.vue';

const props = defineProps({
	items: {
		type: Array as PropType<Array<DropdownOption>>,
		default: () => [],
		required: true,
	},
	modelValue: {
		type: String,
		default: '',
	},
	placeholder: {
		type: String,
		default: '',
	},
	errorMessage: {
		type: String,
		default: undefined,
	},
	itemName: {
		type: String,
		default: 'name',
	},
	itemValue: {
		type: String,
		default: 'value',
	},
	isShowCustomError: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'update:modelValue', value: string): void
}>();

const isOpen = ref(false);

const selectedValue = computed({
	get: () => props.modelValue,
	set: (value) => {
		emit('update:modelValue', value);
	},
});

const chevronIconComponent = computed(() => (isOpen.value ? ChevronUpIcon : ChevronDownIcon));

const isError = computed(() => !!props.errorMessage);

function handleOnMenuChange(isMenuOpen: boolean) {
	isOpen.value = isMenuOpen;
}
</script>

<template>
  <div class="dropdown-wrapper">
    <VSelect
      v-model="selectedValue"
      density="compact"
      variant="outlined"
      :item-title="itemName"
      :item-value="itemValue"
      :class="{ 'error': isError}"
      :placeholder="placeholder"
      :error="isError"
      :items="items"
      :disabled="disabled"
      hide-details
      persistent-placeholder
      @update:menu="handleOnMenuChange"
    >
      <template #append-inner>
        <component
          :is="chevronIconComponent"
          color="#667085"
        />
      </template>
      <template #item="{ props: { title, ...itemsProps}, item }">
        <VListItem
          v-bind="itemsProps"
          class="list-item"
          :ripple="false"
        >
          <div :class="['list-item-inner', {'--selected': selectedValue === item.value}]">
            {{ title }}
            <CheckIcon
              v-if="selectedValue === item.value"
              :color="ICON_COLOR.PRIMARY"
            />
          </div>
        </VListItem>
      </template>
    </VSelect>
    <p
      v-if="isError && !isShowCustomError"
      class="error-message"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
<style scoped lang="scss">
.dropdown-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.list-item {
  margin: spacings-get(1) 0;
}

.list-item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(10);

  &:hover,
  &.--selected {
    border-radius: 6px;
    background-color: colors-get(gray, 50);
  }
}
</style>
