<script setup lang="ts">

const props = defineProps({
	isOpen: {
		type: Boolean,
		required: false,
		default: false,
	},
	maxWidth: {
		type: String,
		required: false,
		default: '400px',
	},
	speed: {
		type: Number,
		required: false,
		default: 300,
	},
	backgroundColor: {
		type: String,
		required: false,
		default: '#fafafa',
	},
	isClosable: {
		type: Boolean,
		default: true,
	},
	persistent: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onClose'): void
}>();

const { width } = useWindowResize();
const isVisible = ref(false);
const isTransitioning = ref(false);
const overlayRef = ref<HTMLElement>();

const drawerMaxWidth = computed((() => {
	return width.value <= SCREEN_SIZE.MOBILE ? 'unset' : props.maxWidth;
}));

function toggleBackgroundScrolling(enable: boolean) {
	const body = document.querySelector('html');
	if (!body) {
		return;
	}
	body.style.overflow = enable ? 'hidden' : '';
}

function handleCloseDrawer() {
	if (props.persistent) {
		return;
	}

	if (!isTransitioning.value) {
		emit('onClose');
	}
}

function handleShow() {
	isTransitioning.value = true;

	toggleBackgroundScrolling(true);
	isVisible.value = true;

	setTimeout(() => (isTransitioning.value = false), props.speed);
}

function handleHide() {
	isTransitioning.value = true;

	toggleBackgroundScrolling(false);
	setTimeout(() => (isVisible.value = false), props.speed);

	setTimeout(() => (isTransitioning.value = false), props.speed);
}

// Add click event listener when the dialog is shown
watch(() => isVisible.value, (newValue) => {
	if (!overlayRef.value) {
		return;
	}
	const overlayBackground = overlayRef.value;

	if (newValue) {
		if (!overlayBackground) {
			return;
		}
		// Check if the element exists
		if (overlayBackground) {
			// Add click event listener
			overlayBackground.addEventListener('click', handleCloseDrawer);
		}
	} else {
		overlayBackground.removeEventListener('click', handleCloseDrawer);
	}
});

onMounted(() => {
	if (props.isOpen) {
		handleShow();
	}
});

onUnmounted(() => {
	toggleBackgroundScrolling(false);
});

watch(() => props.isOpen, (isOpen) => {
	if (isOpen) {
		handleShow();
	} else {
		handleHide();
	}
});
</script>
<template>
  <div
    class="drawer"
    :class="{ 'is-open': isOpen, 'is-visible': isVisible }"
  >
    <div
      ref="overlayRef"
      class="drawer-overlay"
      :style="{ transitionDuration: `${speed}ms` }"
    />
    <div
      class="drawer-wrapper"
      :style="{
        maxWidth: drawerMaxWidth,
        transitionDuration: `${speed}ms`,
      }"
    >
      <div
        v-if="$slots['header'] || isClosable"
        class="drawer-title"
      >
        <div>
          <slot name="header" />
        </div>
        <BaseButton
          v-if="isClosable"
          variant="subtle"
          color="gray"
          size="sm"
          icon-only
          @click="handleCloseDrawer"
        >
          <CloseIcon />
        </BaseButton>
      </div>
      <div :class="['drawer-content', { '--extend': !$slots['header'] || !$slots['footer'] }]">
        <slot name="content" />
      </div>
      <div
        v-if="$slots['footer']"
        class="drawer-footer"
      >
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.drawer {
  visibility: hidden;

  &.is-visible {
    visibility: visible;
  }

  &.is-open {
    .drawer-overlay {
      opacity: 0.7;
    }

    .drawer-wrapper {
      transform: translateX(0);
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    opacity: 0;
    transition-property: opacity;
    background-color: colors-get(gray, 900);
    user-select: none;
  }

  &-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    transition-property: transform;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    background-color: colors-get(base, white);
    box-sizing: border-box;

    .drawer-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: spacings-get(6);
    }

    .drawer-content {
        height: calc(100% - rem(158)) ; // headerHeight(86) + footerHeight(72) = 158
        padding: 0 spacings-get(6);
        overflow: auto;

        &.--extend {
          height: auto;
          margin-bottom: spacings-get(6);
        }
    }

    .drawer-footer {
        height: rem(72);
    }
  }
}
</style>