<script setup lang="ts">
import type { Follower } from '@/types/profile';

defineProps({
	followers: {
		type: Array as PropType<Array<Follower>>,
		default: () => [],
		required: true,
	},
	followerNumber: {
		type: Number,
		default: null,
		required: true,
	},
});

const router = useRouter();
const profileStore = useProfileStore();

const isOpenFollowerDialog = ref(false);

const emit = defineEmits<{(e: 'onFetchData', value: any): void }>();

function handleFetchData($state: any) {
	emit('onFetchData', $state);
}

function handleNavigation(id: number) {
	isOpenFollowerDialog.value = false;

	if (id === profileStore.profile?.id) {
		router.push({
			name: ROUTE.PROFILE.NAME,
		});
	} else {
		router.push({
			name: ROUTE.USER.NAME,
			params: {
				id,
			},
		});
	}
}
</script>

<template>
  <BaseButton
    v-if="followerNumber"
    class="follower-btn"
    variant="link"
    color="primary"
    @click="isOpenFollowerDialog = true"
  >
    {{ `${followerNumber} follower${followerNumber > 1 ? 's' : ''}` }}
  </BaseButton>
  <BaseDialog
    :is-show="isOpenFollowerDialog"
    @on-close="isOpenFollowerDialog = false"
  >
    <template #header>
      <span class="text-lg text-semibold">Followers</span>
    </template>
    <template #content>
      <div class="follower-container">
        <BaseButton
          v-for="follower in followers"
          :key="follower.id"
          class="follower"
          variant="link"
          color="gray"
          @click="handleNavigation(follower.id)"
        >
          <AvatarImage
            :image-url="follower.avatar"
            :alt="follower.nickname"
          />
          <p class="text-md text-medium">
            {{ follower.nickname }}
          </p>
        </BaseButton>
        <InfiniteLoading @infinite="handleFetchData">
          <template #complete>
            <span />
          </template>
          <template #spinner>
            <VSkeletonLoader
              v-for="index in 8"
              :key="index"
              class="mx-auto"
              max-width="352"
              height="64"
              type="list-item-avatar"
            />
          </template>
        </InfiniteLoading>
      </div>
    </template>
  </BaseDialog>
</template>

<style lang="scss" scoped>
.follower-btn {
  width: fit-content;
  padding: 0;
  margin: auto;
}

.follower-container {
  width: 100%;
  max-height: rem(512);
  display: flex;
  flex-direction: column;

  .follower {
    display: grid;
    grid-template-columns: spacings-get(10) 1fr;
    align-items: center;
    justify-items: start;
    column-gap: spacings-get(3);
    padding: spacings-get(3) spacings-get(6) spacings-get(3) 0;
    color: colors-get(gray, 900);

    p {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  :deep(.v-skeleton-loader__avatar) {
    width: spacings-get(10);
    height: spacings-get(10);
    min-width: spacings-get(10);
    min-height: spacings-get(10);
  }
}
</style>
