import type { EVENT_STATUS as EVENT_STATUS_ENUM } from '@/enums/event';

export const EVENT_CHAIN_ID = {
	ETHEREUM: {
		NAME: 'Ethereum',
		ID: 1,
	},
	POLYGON: {
		NAME: 'Polygon',
		ID: 137,
	},
	GOERLI: {
		NAME: 'Goerli',
		ID: 5,
	},
	MUMBAI: {
		NAME: 'Mumbai',
		ID: 80001,
	},
	SEPHOLIA: {
		NAME: 'Sepholia',
		ID: 11155111,
	},
};

export const mappedEventStatus: Record<EVENT_STATUS_ENUM, string> = {
	[EVENT_STATUS.DRAFT]: 'Draft',
	[EVENT_STATUS.OPEN]: 'Published',
	[EVENT_STATUS.FINISHED]: 'Finished',
	[EVENT_STATUS.CANCELED]: 'Canceled',
	[EVENT_STATUS.DELETED]: 'Deleted',
	[EVENT_STATUS.SOLD_OUT]: 'Sold out',
};

export const PURCHASE_LIMIT = 8;