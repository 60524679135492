<script setup lang="ts">
import type { Attendance } from '@/types/attendee';

interface Ticket {
	id: number | null;
	name: string;
	cancelledQty: number;
	restQty: number;
	status: string;
	price: number;
}

const props = defineProps({
	data: {
		type: Array as PropType<Array<Attendance>>,
		default: () => [],
		required: true,
	},
});

const orderTickets = computed(() => {
	if (!props.data) {
		return [];
	}
	const tickets: Ticket[] = [];
	const defaultAttendanceObj: Ticket = {
		id: null,
		name: '',
		cancelledQty: 0,
		restQty: 0,
		status: '',
		price: 0,
	};

	const attendances = props.data;
	for (const attendance of attendances) {
		let ticket = tickets.find((item) => item.name === attendance.ticket.name);

		if (!ticket) {
			// If ticket doesn't exist, create a new one
			ticket = { ...defaultAttendanceObj };
			ticket.id = attendance.id;
			ticket.name = attendance.ticket.name;
			ticket.status = attendance.status;
			tickets.push(ticket);
		}

		if (attendance.status === ATTENDEE_STATUS.CANCELED) {
			ticket.cancelledQty += attendance.quantity;
		} else {
			ticket.restQty += attendance.quantity;
		}
	}

	return tickets;
});

const totalCancelled = computed(() => {
	return orderTickets.value?.reduce((total, curr) => total + curr.cancelledQty, 0);
});
</script>

<template>
  <div class="ticket-detail-container text-left ">
    <p
      v-for="(ticket, ticketIndex) in orderTickets"
      :key="ticketIndex"
      class="ticket text-sm text-regular"
    >
      <span>{{ ticket?.restQty }}x</span>
      <span>{{ " " }}</span>
      <span
        v-if="ticket?.cancelledQty"
        class="cancelled-qty"
      >
        {{ ticket.cancelledQty }}x</span>
      <span v-if="ticket?.cancelledQty">{{ " " }}</span>
      <span>{{ ticket?.name }}</span>
    </p>
    <p
      v-if="totalCancelled"
      class="total text-xs text-regular"
    >
      {{ totalCancelled }} ticket(s) have been canceled.
    </p>
  </div>
</template>

<style scoped lang="scss">
.ticket-detail-container {
  display: flex;
  flex-direction: column;
  row-gap: spacings-get(2);

  .ticket {
    display: flex;
    column-gap: rem(2);
    color: colors-get(gray, 700);

    .cancelled-qty {
      text-decoration: line-through
    }
  }

  .total {
    color: colors-get(gray, 400);
  }
}
</style>
