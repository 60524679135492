import { useGtm as useGtmInstance, type TrackEventOptions } from '@gtm-support/vue-gtm';

export function useGtm() {
	const gtm = useGtmInstance();

	const trackEvent = (eventData?: TrackEventOptions) => {
		if (gtm) {
			gtm.trackEvent({
				...eventData,
			});
		} else {
			console.warn('GTM is not initialized');
		}
	};

	return {
		trackEvent,
	};
}
