<script setup lang="ts">
import type { AuthenticationCodeType, AuthenticationVerifyType } from '@/types/auth';

const props = defineProps({
	type: {
		type: Number as PropType<AuthenticationVerifyType>,
		default: null,
	},
	email: {
		type: String,
		required: true,
	},
	authCodeType: {
		type: String as PropType<AuthenticationCodeType>,
		default: AUTH_CODE_TYPE.ORGANIZER,
	},
	confirmButtonName: {
		type: String,
		default: 'Continue',
	},
});

const authStore = useAuthStore();
const isOpen = ref(false);
const resolver = ref();

const userEmail = computed(() => props.email);

async function handleSendCode() {
	await authStore.sendVerificationCode(userEmail.value, props.authCodeType);
}

async function open() {
	isOpen.value = true;

	// Delay to wait for page to update props before send code
	setTimeout(async () => {
		await handleSendCode();
	}, 0);

	// Return promise and only resolve after the component resolve itself
	return new Promise((resolve) => {
		resolver.value = resolve;
	});
}

function handleCancel() {
	isOpen.value = false;
	resolver.value(false);
}

async function handleSubmitCode(code: string) {
	await authStore.verifyAccountByCode(code, userEmail.value);

	if (authStore.errorMessage) {
		return;
	}

	isOpen.value = false;
	resolver.value(true);
}

defineExpose({
	open,
});

</script>
<template>
  <BaseDialog
    :is-show="isOpen"
    :hide-close-icon="true"
  >
    <template #content>
      <CodeVerificationForm
        class="verify-account"
        :is-loading="false"
        :confirm-button-name="confirmButtonName"
        :server-error="authStore.errorMessage"
        @on-submit-code="handleSubmitCode"
        @on-resend-code="handleSendCode"
      >
        <template #header>
          <div>
            <h1
              class="text-semibold text-lg"
            >
              One-time account verification
            </h1>
            <template v-if="type === AUTH_FORM.PUBLISH_VERIFY">
              <p class="sub-text text-sm text-regular">
                Your account must be verified in order to publish the event.
              </p>
            </template>
          </div>
        </template>
        <template #content>
          <div
            class="text-sm text-align-center text-regular"
          >
            <template v-if="type === AUTH_FORM.PUBLISH_VERIFY">
              We sent a verification code to
              <b class="text-semibold">{{ email }}</b>. Please check
              your email, and enter the code below to verify and publish.
              Code expires in 15 minutes.
            </template>
            <template v-if="type === AUTH_FORM.ACCOUNT_VERIFY">
              We sent a verification code to <b class="text-semibold">{{ email }}</b>. Please check your email, and enter the code below to verify account. Code expires in 15 minutes.
            </template>
          </div>
        </template>

        <template #action>
          <BaseButton
            class="full-width"
            variant="subtle"
            color="gray"
            size="lg"
            @click="handleCancel"
          >
            Cancel
          </BaseButton>
        </template>
      </CodeVerificationForm>
    </template>
  </BaseDialog>
</template>