<script setup lang="ts">
import type { AuthConnectSnsAccountRequest, AuthForm, AuthenticationType } from '@/types/auth';
import type { RouteRecordName } from 'vue-router';

enum AUTH_PAGE {
	LOGIN,
	VERIFICATION,
	CONNECT_WITH_SNS,
}

const props = defineProps({
	type: {
		type: Number as PropType<AuthenticationType>,
		default: AUTH_FORM.SIGN_IN,
	},
});

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();
const notificationStore = useNotificationStore();
const userEmail = ref<string>('');
const step = ref(AUTH_PAGE.LOGIN);
const connectWithSnsType = ref();
const isShowAppleLoginFailedDialog = ref(false);

const backBtnText = computed(() => {
	switch (props.type) {
		case AUTH_FORM.SIGN_IN:
			return 'Back to sign in different ways';
		case AUTH_FORM.SIGN_UP:
			return 'Back to register in different ways';
		default:
			return '';
	}
});

async function handleGoogleLogin(authCode: string) {
	await authStore.authWithGoogle({
		service: AUTH_SERVICE.GOOGLE,
		secret: authCode,
	});

	if (authStore.snsConnectMetaData) {
		connectWithSnsType.value = AUTH_SERVICE.GOOGLE;
		step.value = AUTH_PAGE.CONNECT_WITH_SNS;
		userEmail.value = authStore.snsConnectMetaData.email;
	}
}

async function handleAppleLogin(data: AppleSignInAPI.SignInResponseI) {
	await authStore.authWithApple({
		service: AUTH_SERVICE.APPLE,
		secret: data.authorization.code,
		token: data.authorization.id_token,
		client_id: import.meta.env.VITE_APPLE_CLIENT_ID,
		redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
		user: getDefaultUser(data),
	});

	if (authStore.snsConnectMetaData) {
		connectWithSnsType.value = AUTH_SERVICE.APPLE;
		step.value = AUTH_PAGE.CONNECT_WITH_SNS;
		userEmail.value = authStore.snsConnectMetaData.email;
	}
}

function handleSendCode() {
	authStore.sendVerificationCode(userEmail.value);
}

function handleAuthWithCode(code: string) {
	if (connectWithSnsType.value) {
		const snsConnectPayload: AuthConnectSnsAccountRequest = {
			service: connectWithSnsType.value,
			secret: authStore.snsConnectMetaData?.snsSecret || '',
			...(connectWithSnsType.value === AUTH_SERVICE.APPLE && {
				token: authStore.snsConnectMetaData?.snsToken,
				client_id: import.meta.env.VITE_APPLE_CLIENT_ID,
				redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
				user: authStore.snsConnectMetaData?.user,
			}),
		};
		authStore.authWithCode(code, userEmail.value, snsConnectPayload);
	} else {
		authStore.authWithCode(code, userEmail.value);
	}
}

function handleEmailLogin(form: AuthForm) {
	userEmail.value = form.email;
	switch (props.type) {
		case AUTH_FORM.SIGN_IN:
			authStore.validateEmail(form.email);
			break;
		case AUTH_FORM.SIGN_UP:
			authStore.registerNewUserWithEmail(form);
			break;
		default:
			break;
	}
}

function handleConnectWithSNS() {
	step.value = AUTH_PAGE.VERIFICATION;
	handleSendCode();
}

function handleCancelConnectWithSNS() {
	step.value = AUTH_PAGE.LOGIN;
	connectWithSnsType.value = '';
	userEmail.value = '';
}

onMounted(() => {
	step.value = AUTH_PAGE.LOGIN;
});

watch(
	() => props.type,
	() => {
		step.value = AUTH_PAGE.LOGIN;
	},
);

watch(
	() => authStore.isLoggedIn,
	(newIsLoggedIn) => {
		// Redirect to target page after login if the url contain query string 'redirect'
		if (newIsLoggedIn && route.query.redirect) {
			const newRoute = route.query.redirect as RouteRecordName;
			router.push({
				name: newRoute,
			});
		} else if (newIsLoggedIn) {
			router.replace(ROUTE.INDEX.PATH);
		}
	},
);

watch(
	() => authStore.isSendVerifyCode,
	(newIsSendVerifyCode) => {
		if (newIsSendVerifyCode) {
			step.value = AUTH_PAGE.VERIFICATION;
		}
	},
);

watch(
	() => authStore.isEmailValid,
	(isEmailValid) => {
		if (!isEmailValid) {
			return;
		}
		// TODO: Check if email exist for sign up
		handleSendCode();
	},
);

watch(
	() => authStore.snsConnectErrorMessage,
	(snsConnectErrorMessage) => {
		if (!snsConnectErrorMessage) {
			return;
		}
		notificationStore.showErrorNotification(snsConnectErrorMessage);
	},
);
</script>
<template>
  <div class="signin-page-container">
    <AuthenticationForm
      v-if="step === AUTH_PAGE.LOGIN"
      :type="type"
      :is-loading="authStore.isLoading"
      :sns-server-error-message="authStore.snsErrorMessage"
      :server-error-message="authStore.errorMessage"
      @on-google-login-success="handleGoogleLogin"
      @on-apple-login-success="handleAppleLogin"
      @on-apple-login-failed-by-popup-block="isShowAppleLoginFailedDialog = true"
      @on-submit-email="handleEmailLogin"
    />
    <CodeVerificationForm
      v-if="step === AUTH_PAGE.VERIFICATION"
      class="sign-in"
      :is-loading="authStore.isLoading"
      :server-error="authStore.errorMessage"
      @on-submit-code="handleAuthWithCode"
      @on-resend-code="handleSendCode"
    >
      <template #header>
        <h5
          class="title text-display-sm text-semibold"
        >
          Enter Code
        </h5>
      </template>
      <template #content>
        <AuthenticationContent
          :type="type"
          :email="userEmail"
        />
      </template>
      <template #footer>
        <Divider />
        <BaseButton
          variant="subtle"
          color="primary"
          class="w-full"
          @click="step = AUTH_PAGE.LOGIN"
        >
          <template #leftIcon>
            <ArrowCircleLeftIcon />
          </template>
          {{ backBtnText }}
        </BaseButton>
      </template>
    </CodeVerificationForm>
    <ConnectWithSNSForm
      v-if="step === AUTH_PAGE.CONNECT_WITH_SNS"
      :type="connectWithSnsType"
      @on-confirm="handleConnectWithSNS"
      @on-cancel="handleCancelConnectWithSNS"
    />
    <AppleLoginFailByPopupBlockDialog
      :is-show="isShowAppleLoginFailedDialog"
      @on-close="isShowAppleLoginFailedDialog = false"
      @on-confirm="isShowAppleLoginFailedDialog = false"
    />
  </div>
</template>
<style scoped lang="scss">
.auth-page-container.sign-in {
	gap: spacings-get(8);

	.title {
		margin-bottom: spacings-get(6);
	}

	:deep(.form-container) {
		gap: spacings-get(8);
	}
}
</style>
