<script setup lang="ts">
import { Vue3Lottie } from 'vue3-lottie';
import eventEditorDesktopAnimation from '@/assets/animations/event-editor-desktop.json';
import eventEditorMobileAnimation from '@/assets/animations/event-editor-mobile.json';
import aiCreationPreviewImage from '@/assets/images/ai-creation-preview.png';
import profilePreviewImage from '@/assets/images/profile-preview.png';
import introVideo from '@/assets/animations/home-intro.mp4';
import introPlaceholder from '@/assets/images/home-intro-placeholder.jpeg';

interface ContentItem {
	header: string
	content: string
}

const eventCreationContentItems: ContentItem[] = [
	{
		header: 'Publish in seconds',
		content:
      'Quickly create and edit your event pages, ensuring they look just right before starting sales.',
	},
	{
		header: 'Free, Paid and Token gated ticket types',
		content: 'Offer a variety of tickets options for your events and sell as many as you’d like.',
	},
	{
		header: 'Content crafted by AI',
		content:
      'Enhance your event pages with compelling content suggestions, making your events effortlessly stand out.',
	},
];

const profileContentItems: ContentItem[] = [
	{
		header: 'Grow a following',
		content: 'Attendees can follow you to stay updated on your upcoming events.',
	},
	{
		header: 'Communicate with followers',
		content:
      'Effortlessly engage with your followers through direct updates and announcements through your profile.',
	},
	{
		header: 'Share your events',
		content:
      'Upcoming events will be listed on your profile, making it easy for others to discover and join.',
	},
];

const { isMobile } = useWindowResize();
</script>
<template>
  <div class="page home-page-container">
    <section class="banner-section">
      <div class="content-wrapper">
        <div class="content-inner">
          <div>
            <h1 class="text-semibold">
              Host great events.<br>
              Grow lasting <span class="text-highlight">communities</span>.
            </h1>
            <p class="text-regular">
              More than a ticketing tool, Peatix is your community builder.
            </p>
          </div>
          <RouterLink :to="{ name: ROUTE.EVENT_CREATE.NAME }">
            <BaseButton
              :variant="BtnVariant[1]"
              :color="BtnColor[3]"
              :size="isMobile ? BtnSize[2] : BtnSize[4]"
            >
              Create your event
            </BaseButton>
          </RouterLink>
        </div>
      </div>
      <video
        id="intro-video"
        autoplay
        loop
        muted
        playsinline
        :poster="introPlaceholder"
      >
        <source
          :src="introVideo"
          type="video/mp4"
        >
        Your browser does not support HTML5 video.
      </video>
    </section>

    <!-- Event creation introduction -->
    <section class="event-preview-section">
      <div class="section-inner">
        <h2 class="title text-semibold">
          Easily create events
        </h2>
        <p class="sub-title text-regular">
          Provide a few basic details and let Peatix suggest the rest.
        </p>
        <div class="introduction event-creation">
          <img
            class="preview-image"
            :src="aiCreationPreviewImage"
            alt="AI creation preview"
          >
          <div class="content">
            <ul class="information-list">
              <li
                v-for="(item, index) in eventCreationContentItems"
                :key="index"
              >
                <h3 class="title text-xl text-semibold">
                  {{ item.header }}
                </h3>
                <p class="sub-title text-md text-regular">
                  {{ item.content }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- Event management introduction -->
    <section class="event-preview-section">
      <div class="section-inner">
        <h2 class="title text-semibold">
          A beautiful hosting experience
        </h2>
        <p class="sub-title text-regular">
          Create and manage stunning event pages customized to suit your unique requirements.
        </p>
        <Vue3Lottie
          v-if="isMobile"
          :animation-data="eventEditorMobileAnimation"
        />
        <Vue3Lottie
          v-else
          :animation-data="eventEditorDesktopAnimation"
        />
      </div>
    </section>

    <!-- Profile management introduction -->
    <section>
      <div class="section-inner">
        <h2 class="title text-semibold">
          Build your community
        </h2>
        <p class="sub-title text-regular">
          Host engaging events, forge lasting connections and expand your following.
        </p>
        <div class="introduction profile">
          <div class="content">
            <ul class="information-list">
              <li
                v-for="(item, index) in profileContentItems"
                :key="index"
              >
                <h3 class="title text-xl text-semibold">
                  {{ item.header }}
                </h3>
                <p class="sub-title text-md text-regular">
                  {{ item.content }}
                </p>
              </li>
            </ul>
          </div>
          <img
            class="preview-image"
            :src="profilePreviewImage"
            alt="community preview"
          >
        </div>
      </div>
    </section>

    <!-- Get started -->
    <GetStarted />
  </div>
</template>
<style scoped lang="scss">
.home-page-container {
  text-align: center;
  max-width: none;
  margin: 0;
  padding: 0;

  .title {
    color: colors-get(gray, 900);
  }

  .sub-title {
    color: colors-get(gray, 600);
  }
}

section {
  padding-top: spacings-get(12);
  text-align: center;

  &.event-preview-section {
    background-color: colors-get(gray, 50);
  }

  .section-inner {
    max-width: rem(1008);
    padding: 0 spacings-get(4);
    margin: 0 auto;

    > .title {
      margin-bottom: spacings-get(5);
      @include fonts-size(display-md);

      @include media-query-max(mobile) {
        @include fonts-size(display-sm);
      }
    }

    > .sub-title {
      margin-bottom: spacings-get(12);
      @include fonts-size(text-xl);

      @include media-query-max(mobile) {
        @include fonts-size(text-md);
      }
    }
  }

  .introduction {
    display: grid;
    grid-template-columns: 40% auto;
    padding-bottom: spacings-get(12);

    @include media-query-max(mobile) {
      grid-template-columns: 1fr;
    }

    &.event-creation {
      gap: rem(72);

      @include media-query-max(mobile) {
        gap: spacings-get(10);
      }
    }

    &.profile {
      gap: spacings-get(12);
    }

    .preview-image {
      width: 100%;
    }

    .content {
      text-align: start;
      margin: auto;
    }
  }
}


.banner-section {
  position: relative;
  padding: 0;
  height: rem(600);
  background: colors-get(base, black);

  @include media-query-max(mobile) {
    height: rem(500);
  }

  #intro-video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-position: center;
    object-fit: cover;
    opacity: 0.5;
  }

  .content-wrapper {
    position: absolute;
    display: grid;
    align-items: center;
    width: 100%;
    height: 100%;
    color: colors-get(base, white);
    z-index: 2;

    .content-inner {
      display: flex;
      flex-direction: column;
      gap: spacings-get(6);
      padding: 0 spacings-get(4);
    }

    h1 {
      margin-bottom: spacings-get(4);
      @include fonts-size(display-lg);

      @include media-query-max(mobile) {
        @include fonts-size(display-md);
      }
    }

    p {
      @include fonts-size(text-xl);

      @include media-query-max(mobile) {
        @include fonts-size(text-md);
      }
    }
  }
}
</style>
