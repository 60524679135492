<script setup lang="ts">
import type { TABLE_SORT_DIRECTION as TableSortDirectionType, ALIGNMENT as TableAlignItemType } from '@/enums/common';

defineProps({
	title: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '',
	},
	minWidth: {
		type: String,
		default: '',
	},
	alignItem: {
		type: String as PropType<TableAlignItemType>,
		default: ALIGNMENT.START,
	},
	sortable: {
		type: Boolean,
		default: false,
	},
	isSorted: {
		type: Boolean,
		default: false,
	},
	sortDirection: {
		type: String as PropType<TableSortDirectionType>,
		default: TABLE_SORT_DIRECTION.DESC,
	},
});

defineEmits<{
	(e: 'onToggleSort'): void
}>();

const slots = useSlots();

const hasHeaderContentSlot = computed(() => {
	return !!slots.headerContent;
});
</script>
<template>
  <th
    :class="['v-data-table__td v-data-table__th', `v-data-table-column--align-${alignItem || ALIGNMENT.START}`]"
    :style="[width ? { width: `${width}px` }: {}, minWidth ? { minWidth: `${minWidth}px` }: {}]"
  >
    <slot
      v-if="hasHeaderContentSlot"
      name="headerContent"
    />
    <template v-else>
      <div
        v-if="sortable"
        class="sort-column-wrapper"
        @click="$emit('onToggleSort')"
      >
        <span class="text-xs text-medium">{{ title }}</span>
        <ArrowSortingIcon
          :direction="isSorted ? getSortDirection(sortDirection): ''"
        />
      </div>
      <div
        v-else
        class="v-data-table-header__content"
      >
        <span>{{ title }}</span>
      </div>
    </template>
  </th>
</template>