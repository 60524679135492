import type { FilterInfo, PaymentCard } from '@/types/common';
import type {
	UserProfile,
	ProfileResponse,
	UserGroupResponse,
	GroupInfo,
	UpdatedProfileInfo,
	ProfileMessageInfo,
	FollowerListResponse,
	SocialProfile,
	GroupInfoResponse,
	FollowingListResponse,
	CountryTimezoneRequest,
	CardListResponse,
	TicketPurchasesHistoryResponse,
	AddCreditCardResponse,
} from '@/types/profile';
import type { UserNotificationSetting } from '@/types/user';
import { cloneDeep } from 'lodash';

interface ProfileStore {
	profileData: ProfileResponse | null
	profileGroupData: UserGroupResponse | null
	group: {
		groupInfoData: GroupInfo | null
		updatedGroupInfoData: GroupInfoResponse | null
	}
	follower: {
		data: FollowerListResponse | null
		errorMessage: string
		isLoading: boolean
	}
	following: {
		data: FollowingListResponse | null
		errorMessage: string
	}
	social: {
		data: SocialProfile[]
		errorMessage: string
	}
	card: {
		data: CardListResponse | null
		stripeData: AddCreditCardResponse | null
		errorMessage: string
		isLoading: boolean
	}
	notificationSettingResponse: {
		data: UserNotificationSetting | null
		errorMessage: string
	}
	ticketPurchaseHistory: {
		data: TicketPurchasesHistoryResponse | null
		errorMessage: string
	}
	errorUpdateEmailMessage: string
	errorMessage: string
	isLoading: boolean
}

const useProfileStore = defineStore('profile', {

	state: (): ProfileStore => ({
		profileData: null,
		profileGroupData: null,
		group: {
			groupInfoData: null,
			updatedGroupInfoData: null,
		},
		follower: {
			data: null,
			errorMessage: '',
			isLoading: false,
		},
		following: {
			data: null,
			errorMessage: '',
		},
		social: {
			data: [],
			errorMessage: '',
		},
		card: {
			data: null,
			stripeData: null,
			errorMessage: '',
			isLoading: false,
		},
		notificationSettingResponse: {
			data: null,
			errorMessage: '',
		},
		ticketPurchaseHistory: {
			data: null,
			errorMessage: '',
		},
		errorUpdateEmailMessage: '',
		errorMessage: '',
		isLoading: false,
	}),

	getters: {
		profile: (state: ProfileStore) => {
			if (!state.profileData) {
				return null;
			}
			return transformProfile(state.profileData);
		},

		profileId: (state: ProfileStore) => {
			if (!state.profileData) {
				return null;
			}
			return state.profileData.id;
		},

		profileGroupId: (state: ProfileStore): number | null => {
			if (!state.profileGroupData) {
				return null;
			}
			return state.profileGroupData.id;
		},

		profileGroup: (state: ProfileStore) => {
			if (!state.group.groupInfoData) {
				return null;
			}

			return transformProfileGroup(
				state.group.groupInfoData,
				state.group.updatedGroupInfoData || null,
			);
		},

		socialInfo: (state: ProfileStore) => {
			if (!state.social.data) {
				return null;
			}
			return transformSocialInfo(state.social.data);
		},

		socialLink: (state: ProfileStore) => {
			if (!state.social.data) {
				return null;
			}
			return transformSocialLink(state.social.data);
		},

		notificationSetting: (state: ProfileStore): UserNotificationSetting => {
			return {
				sales: !!state.notificationSettingResponse.data?.sales,
				offer: !!state.notificationSettingResponse.data?.offer,
				recommendation: !!state.notificationSettingResponse.data?.recommendation,
			};
		},

		cardList: (state: ProfileStore): PaymentCard[] => {
			if (!state.card.data) {
				return [];
			}
			return transformCardList(state.card.data);
		},

		followingList: (state: ProfileStore) => {
			if (!state.following.data) {
				return [];
			}
			return transformFollowingList(state.following.data.followingGroups);
		},

		ticketPurchaseHistoryList: (state: ProfileStore) => {
			if (!state.ticketPurchaseHistory?.data) {
				return [];
			}
			return transformTicketPurchaseHistoryList(state.ticketPurchaseHistory.data);
		},

		stripeAddCardKeys: (state: ProfileStore) => {
			if (!state.card?.stripeData) {
				return null;
			}
			return {
				publicKey: state.card.stripeData.paymentSecrets.publicKey,
				clientSecret: state.card.stripeData.paymentSecrets.clientSecret,
				setupIntentsId: state.card.stripeData.setupIntentsId,
			};
		},
	},

	actions: {
		async myAccountInfo() {
			this.isLoading = true;
			this.errorMessage = '';

			const { data: result, errorMessage } = await getMyAccount();

			if (errorMessage.value) {
				// TODO: Get message from API
				this.errorMessage = 'The sign in code you entered is incorrect. Please try again.';
			}

			if (result.value) {
				this.profileData = result.value.data.user;
			}
			this.isLoading = false;
		},

		updateMyAccountVerificationStatus(status: boolean) {
			const cloneProfileData = cloneDeep(this.profileData);
			if (cloneProfileData) {
				this.profileData = {
					...cloneProfileData,
					verification_step: status ? 1 : 0,
				};
			}
		},

		async updateProfile(userId: number, profileInfo: UpdatedProfileInfo) {
			this.isLoading = true;
			this.errorMessage = '';

			const { data: result, errorMessage } = await putUpdateProfileInfo(
				userId,
				profileInfo,
			);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.profileData = result.value.data.user;
			}
			this.isLoading = false;
		},

		async getProfileGroupByUserId(userId: number) {
			this.isLoading = true;
			this.errorMessage = '';

			const { data: userGroups, errorMessage } = await getUserGroups(userId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (userGroups.value && !!userGroups.value.data.length) {
				const firstGroup = userGroups.value.data[0];
				this.profileGroupData = firstGroup;
			}
			this.isLoading = false;
		},

		async getProfileGroup(profile: UserProfile) {
			this.isLoading = true;
			this.errorMessage = '';

			// Get new peasy group
			const { data: userGroups, errorMessage } = await getUserGroups(profile.id);

			if (errorMessage.value) {
				// TODO: Get message from API
				this.errorMessage = errorMessage.value;
			}

			if (userGroups.value && !!userGroups.value.data.length) {
				const firstGroup = userGroups.value.data[0];
				this.profileGroupData = firstGroup;
				this.isLoading = false;
				return;
			}

			// Create new peasy group if not found any peasy group
			const { data: userGroup, errorMessage: createGroupErrorMessage } = await postCreateUserGroup(profile.name);

			if (errorMessage.value) {
				this.errorMessage = createGroupErrorMessage.value;
			}

			if (userGroup.value) {
				this.profileGroupData = userGroup.value;
			}

			// Update default notification settings for peaz's user
			const { data: notificationData, errorMessage: notificationErrorMessage } = await postNotificationSettingAPI(profile.id, {
				sales: true,
				offer: false,
				recommendation: false,
			});

			if (notificationErrorMessage.value) {
				this.notificationSettingResponse.errorMessage = notificationErrorMessage.value;
			}

			if (notificationData.value) {
				this.notificationSettingResponse.data = notificationData.value;
			}
			this.isLoading = false;
		},

		removeProfile() {
			this.profileGroupData = null;
			this.profileData = null;
		},

		async getProfileGroupInfo(groupId: number) {
			this.isLoading = true;
			this.errorMessage = '';
			this.group.groupInfoData = null;

			const { data: groupInfo, errorMessage } = await getGroupInfoByGroupId(groupId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (groupInfo.value) {
				this.group.groupInfoData = groupInfo.value;
			}

			this.isLoading = false;
		},

		async updateGroupInfo(groupId: number, groupInfo: Partial<GroupInfo>) {
			this.isLoading = true;
			this.errorMessage = '';

			const { data: updatedData, errorMessage } = await patchUpdateGroupInfo(
				groupId,
				groupInfo,
			);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (updatedData.value) {
				this.group.updatedGroupInfoData = updatedData.value;
			}
			this.isLoading = false;
		},

		async uploadGroupCoverImage(eventId: number, file: File) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await postGroupCoverImageAPI(eventId, file);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
		},

		async deleteGroupCoverImage(eventId: number) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await deleteGroupCoverImageAPI(eventId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
		},

		async sendMessageToFollowers(
			groupId: number,
			info: ProfileMessageInfo,
		) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await postMessageToFollower(
				groupId,
				info,
			);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			this.isLoading = false;
		},

		async getFollowerList(userGroupId: number, filter: FilterInfo) {
			this.follower.isLoading = true;
			this.follower.data = null;

			const { data, errorMessage } = await getFollowerListByUserId(userGroupId, filter);

			if (errorMessage.value) {
				this.follower.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.follower.data = data.value;
			}
			this.follower.isLoading = false;
		},

		async getFollowingList(
			userId: number,
			filter: FilterInfo,
		) {
			this.isLoading = true;

			const { data, errorMessage } = await getFollowingListByUserId(userId, {
				...filter,
				sortBy: filter.sortBy ?? FOLLOWING_SORTING_METHOD.ALPHABETICAL_A2Z,
				itemPerPage: filter.itemPerPage ?? PAGINATION_CONFIG.ITEM_PER_PAGE,
				role: filter.role ?? FOLLOWING_TYPE.FOLLOWER,
			});

			if (errorMessage.value) {
				this.following.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.following.data = data.value;
			}
			this.isLoading = false;
		},

		async getSocialProfile(userId: number) {
			this.isLoading = true;
			this.social.data = [];

			const { data, errorMessage } = await getSocialProfileByUserId(userId);

			if (errorMessage.value) {
				this.social.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.social.data = data.value.data;
			}
			this.isLoading = false;
		},

		async updateSocialProfile(userId: number, socialProfile: SocialProfile[]) {
			this.isLoading = true;

			const { data: result, errorMessage } = await putUpdateSocialProfile(
				userId,
				socialProfile,
			);

			if (errorMessage.value) {
				this.social.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.social.data = result.value.data;
			}
			this.isLoading = false;
		},


		async getNotificationSetting(userId: number) {
			this.isLoading = true;
			this.notificationSettingResponse.errorMessage = '';

			const { data, errorMessage } = await getNotificationSettingAPI(userId);

			if (errorMessage.value) {
				this.notificationSettingResponse.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.notificationSettingResponse.data = data.value;
			}
			this.isLoading = false;
		},

		async updateNotificationSetting(userId: number, payload: UserNotificationSetting) {
			this.isLoading = true;
			this.notificationSettingResponse.errorMessage = '';

			const { data, errorMessage } = await postNotificationSettingAPI(userId, payload);

			if (errorMessage.value) {
				this.notificationSettingResponse.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.notificationSettingResponse.data = data.value;
			}
			this.isLoading = false;
		},

		async updateProfileEmail(userId: number, email: string, identityToken: string) {
			this.isLoading = true;
			this.errorUpdateEmailMessage = '';

			const { errorMessage } = await patchUpdateProfileEmail(
				userId,
				email,
				identityToken,
			);

			if (errorMessage.value) {
				this.errorUpdateEmailMessage = 'This email address has already been registered. Please try again with another email address.';
			}
			this.isLoading = false;
		},

		async updateProfileCountryTimezone(userId: number, payload: CountryTimezoneRequest) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await patchUpdateProfileCountryTimezone(
				userId,
				payload,
			);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
		},

		async getCardList(
			userId: number,
			filter: FilterInfo,
		) {
			this.card.isLoading = true;
			this.card.errorMessage = '';

			const { data, errorMessage } = await getCardListByUserId(
				userId,
				{
					...filter,
					sortBy: filter.sortBy ?? SAVED_CARD_SORTING_METHOD.LATEST_CREATED,
					itemPerPage: filter.itemPerPage ?? PAGINATION_CONFIG.ITEM_PER_PAGE,
				},
			);

			if (errorMessage.value) {
				this.card.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.card.data = data.value;
			}
			this.card.isLoading = false;
		},

		async deleteCard(userId: number, cardId: string) {
			this.card.isLoading = true;
			this.card.errorMessage = '';

			const { errorMessage } = await deleteCardById(
				userId,
				cardId,
			);

			if (errorMessage.value) {
				this.card.errorMessage = errorMessage.value;
			}
			this.card.isLoading = false;
		},

		async addCard() {
			this.isLoading = true;
			this.card.errorMessage = '';

			const { data, errorMessage } = await postAddCreditCard();

			if (errorMessage.value) {
				this.card.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.card.stripeData = data.value;
			}
			this.isLoading = false;
		},

		async getTicketPurchaseHistoryList(filter: FilterInfo) {
			this.isLoading = true;

			const { data, errorMessage } = await getTicketPurchasesHistory(filter);

			if (errorMessage.value) {
				this.ticketPurchaseHistory.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.ticketPurchaseHistory.data = data.value;
			}
			this.isLoading = false;
		},
	},
});

export default useProfileStore;
