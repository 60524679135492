<script setup lang="ts">
import pictorialLogoImageUrl from '@/assets/svg/peatix-logo-green.svg';
import logoImageUrl from '@/assets/svg/peatix-logo.svg';
import whiteLogoImageUrl from '@/assets/svg/peatix-logo-white.svg';

const props = defineProps({
	isTransparentBackground: {
		type: Boolean,
		default: false,
		required: true,
	},
});

const layoutStore = useLayoutStore();

const defaultLogoImageUrl = computed(() => (props.isTransparentBackground ? whiteLogoImageUrl : logoImageUrl));

const imageUrl = computed(() => {
	return layoutStore.isPublic ? pictorialLogoImageUrl : defaultLogoImageUrl.value;
});
</script>
<template>
  <img
    alt="logo"
    :src="imageUrl"
  >
</template>
