<script setup lang="ts">
import type { Profile } from '@/types/profile';
import useVuelidate from '@vuelidate/core';
import { helpers, maxLength, required } from '@vuelidate/validators';
import FacebookIcon from '@/components/icons/FacebookIcon.vue';
import XIcon from '@/components/icons/XIcon.vue';
import InstagramIcon from '@/components/icons/InstagramIcon.vue';
import TiktokIcon from '@/components/icons/TiktokIcon.vue';
import YoutubeIcon from '@/components/icons/YoutubeIcon.vue';
import LinkedInIcon from '@/components/icons/LinkedInIcon.vue';
import avatarPlaceholder from '@/assets/svg/user-circle.svg';
import type { IObjectValidationKeys } from '@/types/common';

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const profileStore = useProfileStore();
const notificationStore = useNotificationStore();

const emit = defineEmits<{
	(e: 'onClose'): void
	(e: 'onUpdate', value: Profile): void
}>();

const readableMaximumSize = formatByteSize(MAXIMUM_FILE_SIZE);

const profileFileUpload = ref<HTMLElement | null>();
const coverFileUpload = ref<HTMLElement | null>();
const tempProfileImageUrl = ref('');
const tempCoverImageUrl = ref('');
const tempProfileImageFile = ref<File | null>(null);
const tempCoverImageFile = ref<File | null>(null);

const formData = reactive<Profile>({
	profileName: '',
	profileImageUrl: '',
	profileImageFile: null,
	groupDescription: '',
	groupCoverImageUrl: '',
	groupCoverImageFile: null,
	hasDeletedImage: false,
	socials: {
		facebook: '',
		x: '',
		instagram: '',
		tiktok: '',
		youtube: '',
		linkedin: '',
		website: '',
	},
});

const socials = [
	{
		name: SOCIAL_NAME.FACEBOOK,
		icon: FacebookIcon,
		fieldName: SOCIAL_PREFIX_LINK.FACEBOOK,
	},
	{
		name: SOCIAL_NAME.X,
		icon: XIcon,
		fieldName: SOCIAL_PREFIX_LINK.X,
	},
	{
		name: SOCIAL_NAME.INSTAGRAM,
		icon: InstagramIcon,
		fieldName: SOCIAL_PREFIX_LINK.INSTAGRAM,
	},
	{
		name: SOCIAL_NAME.TIKTOK,
		icon: TiktokIcon,
		fieldName: SOCIAL_PREFIX_LINK.TIKTOK,
	},
	{
		name: SOCIAL_NAME.YOUTUBE,
		icon: YoutubeIcon,
		fieldName: SOCIAL_PREFIX_LINK.YOUTUBE,
	},
	{
		name: SOCIAL_NAME.LINKEDIN,
		icon: LinkedInIcon,
		fieldName: SOCIAL_PREFIX_LINK.LINKEDIN,
	},
];

const isShowValue = computed(() => props.isShow);

const uploadCoverImageBtnName = computed(() => {
	return formData.groupCoverImageUrl ? 'Change cover image' : 'Add cover image';
});

const avatarImage = computed(() => {
	return formData?.profileImageUrl ? formData?.profileImageUrl : avatarPlaceholder;
});

const rules = computed(() => {
	if (!props.isShow) {
		return {
			profileName: {},
			socials: {},
		};
	}

	const socialsRules: IObjectValidationKeys = {};
	for (const key in formData.socials) {
		// Filter unwanted properties from the prototype
		if (Object.prototype.hasOwnProperty.call(formData.socials, key)) {
			socialsRules[key] = {
				maxLength: helpers.withMessage(
					() => {
						const inputLength = Math.abs(64 - formData.socials[key].length);
						return `You have exceed the maximum character limit by ${inputLength} characters.`;
					},
					maxLength(64),
				),
			};
		}
	}

	return {
		profileName: {
			required: helpers.withMessage('Please enter your name.', required),
		},
		socials: socialsRules,
	};
});

const v$ = useVuelidate(rules, formData);

function derivedSocialErrorMessage(propName: string) {
	const errMessage = getValidationErrorMessageWithPropName(v$.value.socials.$errors, propName);
	return errMessage;
}

function handleOnWebsiteChange() {
	const inputValue = formData.socials.website.trim();

	// If it's empty or already has a protocol, do nothing
	// If it's not empty and doesn't start with a protocol, add "https://"
	if (inputValue && !/^https?:\/\//i.test(inputValue)) {
		formData.socials.website = `https://${inputValue}`;
	}
}

function updateProfile() {
	if (profileStore.profile) {
		formData.profileName = profileStore.profile.name;
		// Replace space with empty string
		formData.groupDescription = profileStore.profileGroup?.description === ' ' || !profileStore.profileGroup?.description ? '' : profileStore.profileGroup?.description;
		formData.profileImageUrl = profileStore.profile.avatarLargeUrl;
		formData.groupCoverImageUrl = profileStore.profileGroup?.cover || '';
	}

	if (profileStore.socialInfo) {
		formData.socials = profileStore.socialInfo;
	}
}

async function handleSelectProfileFileUpload(e: Event) {
	const target = e.target as HTMLInputElement;
	if (target && target.files?.length) {
		tempProfileImageFile.value = target.files[0];
		if (tempProfileImageFile.value?.size > MAXIMUM_FILE_SIZE) {
			notificationStore.showErrorNotification(`The image format is not supported. JPEG, PNG or GIF are supported with a maximum file size of ${readableMaximumSize}.`);
			tempProfileImageFile.value = null;
			return;
		}

		// Create a temporary preview image URL before save the file on API call
		tempProfileImageUrl.value = URL.createObjectURL(tempProfileImageFile.value);
		formData.profileImageUrl = tempProfileImageUrl.value;
		formData.profileImageFile = tempProfileImageFile.value;

		// Reset file upload
		target.value = '';
	} else if (!target.files?.length) {
		formData.profileImageFile = null;
		tempProfileImageFile.value = null;
	}
}

async function handleSelectCoverFileUpload(e: Event) {
	const target = e.target as HTMLInputElement;

	if (target && target.files?.length) {
		tempCoverImageFile.value = target.files[0];

		if (tempCoverImageFile.value?.size > MAXIMUM_FILE_SIZE) {
			notificationStore.showErrorNotification(`The image format is not supported. JPEG, PNG or GIF are supported with a maximum file size of ${readableMaximumSize}.`);
			tempCoverImageFile.value = null;
			return;
		}

		// Create a temporary preview image URL before save the file on API call
		tempCoverImageUrl.value = URL.createObjectURL(tempCoverImageFile.value);
		formData.hasDeletedImage = false;
		formData.groupCoverImageUrl = tempCoverImageUrl.value;
		formData.groupCoverImageFile = tempCoverImageFile.value;

		// Reset file upload
		target.value = '';
	} else if (!target.files?.length) {
		formData.groupCoverImageFile = null;
		tempCoverImageFile.value = null;
	}
}

function handleDeleteImage() {
	// Reset flag to prevent trigger auto save when get new data
	const input = coverFileUpload.value as HTMLInputElement;
	input.value = '';

	formData.groupCoverImageUrl = '';
	formData.groupCoverImageFile = null;

	formData.hasDeletedImage = true;
}

function handleSubmit() {
	v$.value.$touch();

	if (v$.value.$error) {
		return;
	}
	emit('onUpdate', formData);
}

function handleClose() {
	updateProfile();
	v$.value.$reset();
	emit('onClose');
}

onMounted(() => {
	updateProfile();
});

// Watch for changes in the profile data and update formData
watch(() => profileStore.profile, (newData) => {
	if (newData) {
		formData.profileName = newData.name;
		formData.profileImageUrl = newData.avatarLargeUrl;
	}
});

// Watch for changes in the group data and update formData
watch(() => profileStore.profileGroup, (newData) => {
	if (newData?.description && newData?.cover) {
		formData.groupDescription = newData.description;
		formData.groupCoverImageUrl = newData.cover;
	}
});

// Reset state when show dialog
watch(() => props.isShow, (newIsShow) => {
	if (newIsShow) {
		updateProfile();
		v$.value.$reset();
	}
});
</script>

<template>
  <BaseDialog
    :is-show="isShowValue"
    :size="DIALOG_SIZE.LARGE"
    @on-close="handleClose"
  >
    <template #header>
      <p class="text-lg text-semibold">
        Edit profile
      </p>
    </template>
    <template #content>
      <div class="profile-content-container">
        <div class="profile-editor-container">
          <!-- cover image -->
          <div class="group-cover-container text-md text-regular">
            <div :class="['cover-image', { 'place-holder': !formData.groupCoverImageUrl }]">
              <img
                v-if="formData.groupCoverImageUrl"
                :src="formData.groupCoverImageUrl"
              >
              <div class="action-btn-container">
                <BaseButton
                  class="add-btn"
                  size="sm"
                  variant="outlined"
                  color="gray"
                  :disabled="disabled"
                  @click="coverFileUpload?.click()"
                >
                  <UploadIcon />
                  {{ uploadCoverImageBtnName }}
                </BaseButton>
                <input
                  ref="coverFileUpload"
                  type="file"
                  hidden
                  accept="image/png, image/gif, image/jpeg"
                  @change="handleSelectCoverFileUpload"
                >
                <BaseButton
                  v-if="formData.groupCoverImageUrl"
                  class="del-btn"
                  variant="outlined"
                  color="gray"
                  :disabled="disabled"
                  icon-only
                  @click="handleDeleteImage"
                >
                  <TrashIcon />
                </BaseButton>
              </div>
            </div>
          </div>
          <!-- profile image -->
          <div class="profile-img-container">
            <div class="profile-image">
              <AvatarImage
                class="img-wrapper"
                :image-url="avatarImage"
                :alt="formData?.profileName"
              />
              <div class="action-btn-container">
                <BaseButton
                  class="add-btn"
                  size="sm"
                  variant="outlined"
                  color="gray"
                  :disabled="disabled"
                  @click="profileFileUpload?.click()"
                >
                  <UploadIcon />
                </BaseButton>
                <input
                  ref="profileFileUpload"
                  type="file"
                  hidden
                  accept="image/png, image/gif, image/jpeg"
                  @change="handleSelectProfileFileUpload"
                >
              </div>
            </div>
          </div>
          <BaseTextInput
            v-model="formData.profileName"
            class="title-input"
            placeholder="Name"
            :disabled="disabled"
            :error-message="getValidationErrorMessage(v$.profileName.$errors)"
          />
          <BaseTextArea
            v-model="formData.groupDescription"
            placeholder="Enter description"
            rows="5"
            :disabled="disabled"
          />
        </div>
        <div class="profile-social-container">
          <h4 class="text-sm text-medium">
            Social links
          </h4>
          <div
            v-for="(social, index) in socials"
            :key="index"
            class="social-input-group"
          >
            <div class="social-input-wrapper">
              <component :is="social.icon" />
              <BaseTextInput
                v-model="formData.socials[social.name]"
                class="social-input"
                placeholder="username"
                :disabled="disabled"
                is-show-custom-error
              >
                <template #prepend>
                  <p>{{ social.fieldName }}</p>
                </template>
              </BaseTextInput>
            </div>
            <p
              v-if="!!derivedSocialErrorMessage"
              class="error-message"
            >
              {{ derivedSocialErrorMessage(social.name) }}
            </p>
          </div>
          <div class="website-input-group">
            <GlobeIcon />
            <BaseTextInput
              v-model="formData.socials.website"
              class="social-input"
              :placeholder="SOCIAL_PREFIX_LINK.WEBSITE"
              :disabled="disabled"
              @change="handleOnWebsiteChange"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="btn-container">
        <BaseButton
          class="w-full"
          variant="outlined"
          color="gray"
          type="button"
          :disabled="disabled"
          @click="handleClose"
        >
          Cancel
        </BaseButton>
        <BaseButton
          class="w-full"
          variant="solid"
          color="primary"
          type="submit"
          :disabled="disabled"
          @click="handleSubmit"
        >
          Save
        </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.profile-content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  @include media-query-max(mobile) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.profile-editor-container {
  display: flex;
  flex-direction: column;
  gap: spacings-get(5);
  padding: 0 spacings-get(6) 0 0;
  border-right: 1px solid colors-get(gray, 200);
  border-bottom: unset;

  @include media-query-max(mobile) {
    padding: 0 0 spacings-get(5) 0;
    border-right: unset;
    border-bottom: 1px solid colors-get(gray, 200);
  }

  .group-cover-container {
    .cover-image {
      width: 100%;
      position: relative;
      display: flex;
      overflow: hidden;
      color: colors-get(gray, 500);
      background-color: colors-get(gray, 200);
      @include border-radius-default;
      aspect-ratio: 3 / 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .action-btn-container {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: spacings-get(3);
        display: flex;
        gap: spacings-get(2);

        .add-btn {
          padding: spacings-get(2) rem(14);
          gap: spacings-get(2);
        }

        .del-btn {
          padding: spacings-get(2);
        }
      }
    }
  }

  .profile-img-container {
    position: relative;

    .profile-image {
      position: relative;
      width: rem(96);
      height: rem(96);
      background-color: transparent;
      aspect-ratio: 1;

      .img-wrapper {
        width: 100%;
        height: 100%;
        @include border-radius-rounded;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }


      .action-btn-container {
        position: absolute;
        bottom: 0;
        right: 0;

        .add-btn {
          width: rem(36);
          padding: spacings-get(2);
          @include border-radius-rounded;
          aspect-ratio: 1;
        }
      }
    }
  }
}

.profile-social-container {
  display: flex;
  flex-direction: column;
  row-gap: spacings-get(3);
  padding: 0 0 0 spacings-get(6);

  @include media-query-max(mobile) {
    padding: spacings-get(5) 0 0 0;
  }

  .social-input-group {
    display: flex;
    flex-direction: column;

    .social-input-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: spacings-get(3);
    }

    .error-message {
      margin-left: rem(36);
    }
  }

  .website-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: spacings-get(3);
  }

  .social-input {
    width: 100%;
  }

  .website {
    height: 100%;
    padding: rem(10) rem(14);
    border-right: 1px solid colors-get(gray, 300);
  }
}

.btn-container {
  display: flex;
  gap: spacings-get(3);
  align-items: center;
  justify-content: space-between;
}
</style>