export function useStripeConnect(isEnable = false, timeout = 30000, interval = 5000) {
	const notificationStore = useNotificationStore();
	const profileStore = useProfileStore();
	const stripeConnectStore = useStripeConnectStore();
	const route = useRoute();
	const router = useRouter();

	const isStripeConnected = ref(false);
	const hasStatusQuery = ref(false);
	const onboardingStatusIntervalId = ref();
	const pollingTimeoutId = ref();

	const profileId = computed(() => profileStore.profile?.id);
	const statusQuery = computed(() => route.query?.['connect-status']);
	const isFetchable = computed(() => !!statusQuery.value || isEnable);

	function clearPolling() {
		if (onboardingStatusIntervalId.value) {
			clearInterval(onboardingStatusIntervalId.value);
			onboardingStatusIntervalId.value = null;
		}
		if (pollingTimeoutId.value) {
			clearTimeout(pollingTimeoutId.value);
			pollingTimeoutId.value = null;
		}
		isStripeConnected.value = false;
	}

	async function handlePolling() {
		isStripeConnected.value = true;

		// Remove query parameters from the URL
		const urlWithoutQuery = window.location.href.split('?')?.[0];
		window.history.replaceState(null, '', urlWithoutQuery);

		// Set a timeout to stop polling after 60 seconds
		pollingTimeoutId.value = setTimeout(() => {
			clearPolling();
			notificationStore.showErrorNotification('Could not connect to your Stripe account. Please try again.');
		}, timeout);

		// Polling every interval seconds
		onboardingStatusIntervalId.value = setInterval(async () => {
			if (!profileId.value) {
				return;
			}
			await stripeConnectStore.getStripeAccountInfo(profileId.value);
		}, interval);
	}

	watch(
		() => profileId.value,
		async (newId) => {
			if (newId && isFetchable.value) {
				await stripeConnectStore.getStripeAccountInfo(newId);
			}
		},
	);

	// Watch for changes in errorMessage
	watch(
		() => stripeConnectStore.connect.errorMessage,
		(errorMessage) => {
			if (errorMessage && errorMessage === STRIPE_CONNECT_ERROR_MESSAGE.MISSING_CONNECT_ACCOUNT) {
				stripeConnectStore.connect.data = null;
			} else if (
				errorMessage &&
        errorMessage !== STRIPE_CONNECT_ERROR_MESSAGE.MISSING_CONNECT_ACCOUNT &&
        errorMessage !== STRIPE_CONNECT_ERROR_MESSAGE.HAS_ACTIVE_EVENTS
			) {
				// Handle error
				clearPolling();
				notificationStore.showErrorNotification('Could not connect to your Stripe account. Please try again.');
			}
		},
	);

	// Watch for changes in isStripeAccountEnabled status change
	watch(
		() => stripeConnectStore.isStripeAccountEnabled,
		(newValue, prevValue) => {
			if (newValue && newValue !== prevValue && hasStatusQuery.value) {
				// Handle success
				clearPolling();
				notificationStore.showSuccessNotification('Successfully connected to your Stripe account.');
			}
		},
	);

	// Check the query in onBeforeMount to capture it early
	onBeforeMount(() => {
		const status = route.query?.['connect-status'];
		if (status) {
			hasStatusQuery.value = true;
		}
	});

	onMounted(async () => {
		if (profileId.value && isFetchable.value) {
			await stripeConnectStore.getStripeAccountInfo(profileId.value);
		}

		// Check if the account redirect back from Stripe connection
		if (statusQuery.value && statusQuery.value !== STRIPE_CONNECT_STATUS.FAILED) {
			handlePolling();
		}
	});

	onUnmounted(() => {
		clearPolling();

		// Remove query string
		router.push({ query: {} });
	});

	return {
		isStripeConnected,
		hasStatusQuery: hasStatusQuery.value,
	};
}
