<script setup lang="ts">
import type { SortItem, TableHeader } from '@/types/common';
import type { TicketPurchaseHistoryItemInfo } from '@/types/profile';

const profileStore = useProfileStore();
const { isTablet } = useWindowResize();
const sortMethod = ref();

const dataItems = ref<TicketPurchaseHistoryItemInfo[]>([]);
const isLoading = computed(() => profileStore.isLoading);
const paginationInfo = computed(() => profileStore.ticketPurchaseHistory.data?.paginationInfo);
const expandedRows = computed(() => dataItems.value.map((item) => item.salesId.toString()));

const headers: TableHeader[] = [
	{
		title: 'Order ID',
		width: '90',
		key: TICKET_PURCHASE_HISTORY_COLUMN_KEY.SALES_ID,
		sortable: false,
	},
	{
		title: 'Event',
		minWidth: '160',
		key: TICKET_PURCHASE_HISTORY_COLUMN_KEY.NAME,
		sortable: true,
	},
	{
		title: 'Date',
		width: '130',
		key: TICKET_PURCHASE_HISTORY_COLUMN_KEY.DATE,
		sortable: true,
	},
	{
		title: 'Status',
		width: '120',
		key: TICKET_PURCHASE_HISTORY_COLUMN_KEY.STATUS,
		sortable: false,
	},
	{
		title: 'Amount',
		align: ALIGNMENT.END,
		width: '120',
		key: TICKET_PURCHASE_HISTORY_COLUMN_KEY.AMOUNT,
		sortable: false,
	},
	{
		title: 'Receipt',
		align: ALIGNMENT.END,
		width: '160',
		sortable: false,
	},
];

const firstColumnGroup: string[] = [
	TICKET_PURCHASE_HISTORY_COLUMN_KEY.SALES_ID,
	TICKET_PURCHASE_HISTORY_COLUMN_KEY.NAME,
	TICKET_PURCHASE_HISTORY_COLUMN_KEY.DATE,
	TICKET_PURCHASE_HISTORY_COLUMN_KEY.STATUS,
];

async function fetchData(page: number) {
	const sortBy = sortMethod.value || `${SORT_DIRECTION.DESC}(${TICKET_PURCHASE_HISTORY_SORTING_METHOD.DATE})`;
	await profileStore.getTicketPurchaseHistoryList({
		currentPage: page,
		itemPerPage: PAGINATION_CONFIG.ITEM_PER_PAGE,
		sortBy,
	});
}

function handlePageChange(page: number) {
	fetchData(page);
}

function handleSortColumn(sortItems: SortItem[]) {
	if (sortItems.length) {
		const sortItem = sortItems[0];
		let sortColumnName = '';
		switch (sortItem.key) {
			case TICKET_PURCHASE_HISTORY_COLUMN_KEY.NAME:
				sortColumnName = TICKET_PURCHASE_HISTORY_SORTING_METHOD.NAME;
				break;
			case TICKET_PURCHASE_HISTORY_COLUMN_KEY.AMOUNT:
				sortColumnName = TICKET_PURCHASE_HISTORY_SORTING_METHOD.AMOUNT;
				break;
			case TICKET_PURCHASE_HISTORY_COLUMN_KEY.DATE:
				sortColumnName = TICKET_PURCHASE_HISTORY_SORTING_METHOD.DATE;
				break;
			case TICKET_PURCHASE_HISTORY_COLUMN_KEY.STATUS:
				sortColumnName = TICKET_PURCHASE_HISTORY_SORTING_METHOD.STATUS;
				break;
			default:
				break;
		}

		const sortQuery = `${sortItem.order}(${sortColumnName})`;
		sortMethod.value = sortQuery;
	} else {
		sortMethod.value = '';
	}

	fetchData(PAGINATION_CONFIG.DEFAULT_PAGE);
}

function getTicketPurchaseHistoryStatusColor(status: string) {
	switch (status) {
		case TICKET_PURCHASE_HISTORY_STATUS.REFUND:
			return CHIP_COLOR.PRIMARY;
		case TICKET_PURCHASE_HISTORY_STATUS.PAID:
			return CHIP_COLOR.SUCCESS;
		default:
			return CHIP_COLOR.PRIMARY;
	}
}

function getTicketPurchaseHistoryStatusName(status: string) {
	switch (status) {
		case TICKET_PURCHASE_HISTORY_STATUS.REFUND:
			return mappedTicketPurchaseHistoryStatusName[TICKET_PURCHASE_HISTORY_STATUS.REFUND];
		case TICKET_PURCHASE_HISTORY_STATUS.PAID:
			return mappedTicketPurchaseHistoryStatusName[TICKET_PURCHASE_HISTORY_STATUS.PAID];
		default:
			return status;
	}
}

function getTicketOrderHistoryStatusColor(status: string, amount: number) {
	switch (status) {
		case TICKET_ORDER_HISTORY_STATUS.CANCELED:
			if (amount > 0) {
				return CHIP_COLOR.PRIMARY;
			}
			return CHIP_COLOR.GRAY;
		case TICKET_ORDER_HISTORY_STATUS.WAITING:
			return CHIP_COLOR.SUCCESS;
		default:
			return CHIP_COLOR.PRIMARY;
	}
}

function getTicketOrderHistoryStatusName(status: string, amount: number) {
	switch (status) {
		case TICKET_ORDER_HISTORY_STATUS.CANCELED:
			if (amount > 0) {
				return 'Refunded';
			}
			return 'Canceled';
		case TICKET_ORDER_HISTORY_STATUS.WAITING:
			return 'Paid';
		default:
			return status;
	}
}

function isRefunded(status: string, amount: number) {
	return status === TICKET_ORDER_HISTORY_STATUS.CANCELED && amount > 0;
}

function getTicketOrderHistoryAmount(status: string, amount: number) {
	if (status === TICKET_ORDER_HISTORY_STATUS.CANCELED && amount > 0) {
		return `-${formatCurrency(amount)}`;
	}
	return formatCurrency(amount);
}

onMounted(() => {
	fetchData(PAGINATION_CONFIG.DEFAULT_PAGE);
});

watch(() => profileStore.ticketPurchaseHistoryList, (newTicketPurchaseHistoryList) => {
	dataItems.value = newTicketPurchaseHistoryList;
});
</script>

<template>
  <div>
    <VDataTable
      class="table-expandable"
      :expanded="expandedRows"
      :show-current-page="false"
      :headers="(headers as any) || []"
      :items="dataItems"
      item-value="salesId"
      density="compact"
      @update:sort-by="handleSortColumn"
    >
      <template #headers="{ columns, isSorted, getSortIcon, toggleSort }">
        <tr v-if="!isTablet">
          <template
            v-for="column in columns"
            :key="column.key"
          >
            <TableHeaderColumn
              :title="column.title || ''"
              :width="(column.width as string) || ''"
              :min-width="(column.minWidth as string) || ''"
              :align-item="column.align || ''"
              :sortable="column.sortable"
              :is-sorted="isSorted(column)"
              :sort-direction="getSortIcon(column)"
              @on-toggle-sort="toggleSort(column)"
            />
          </template>
        </tr>
        <tr v-else>
          <TableHeaderColumn class="w-full">
            <template #headerContent>
              <div class="sort-column-wrapper">
                <template
                  v-for="(column, index) in columns"
                  :key="index"
                >
                  <template v-if="firstColumnGroup.includes(column.key || '')">
                    <div
                      :class="{'sort-header': column.sortable}"
                      @click="toggleSort(column)"
                    >
                      <span class="text-xs text-medium">{{ column.title }}</span>
                      <ArrowSortingIcon
                        v-if="column.sortable"
                        :direction="isSorted(column) ? getSortDirection(getSortIcon(column)): ''"
                      />
                    </div>
                    <div v-if="(index + 1) !== firstColumnGroup.length">
                      /
                    </div>
                  </template>
                </template>
              </div>
            </template>
          </TableHeaderColumn>
          <TableHeaderColumn
            width="120"
            align-item="end"
          >
            <template #headerContent>
              <template v-for="(column, index) in columns">
                <div
                  v-if="column.key === TICKET_PURCHASE_HISTORY_COLUMN_KEY.AMOUNT"
                  :key="index"
                  class="sort-column-wrapper"
                  @click="toggleSort(column)"
                >
                  <span>{{ column.title }}</span>
                  <ArrowSortingIcon
                    :direction="isSorted(column) ? getSortDirection(getSortIcon(column)): ''"
                  />
                </div>
              </template>
            </template>
          </TableHeaderColumn>
        </tr>
      </template>
      <template #no-data>
        <div
          v-if="!isLoading"
          class="table-no-data"
        >
          <SearchIcon
            class="icon"
            width="24"
            height="24"
          />
          <p class="header text-md text-semibold">
            No ticket purchase history found
          </p>
          <p class="text-sm text-regular">
            Your ticket purchase history will be listed here after you have made a purchase.
          </p>
        </div>
        <MultipleSkeletonLoader
          v-else
          rows="10"
          space="0"
          has-divider
        >
          <div class="table-loader-row">
            <template v-if="!isTablet">
              <template
                v-for="header in headers"
                :key="header.title"
              >
                <ButtonSkeletonLoader
                  v-if="!header.key"
                  class="btn-loader"
                  height="38"
                  width="109"
                />
                <VSkeletonLoader
                  v-else
                  type="list-item"
                />
              </template>
            </template>
            <template v-if="isTablet">
              <SentencesSkeletonLoader />
              <VSkeletonLoader
                type="list-item"
              />
              <ButtonSkeletonLoader
                class="btn-loader"
                height="38"
                width="38"
              />
            </template>
          </div>
        </MultipleSkeletonLoader>
      </template>
      <template #item="{ item }">
        <tr :class="{'expanded-row': item.orderHistories.length }">
          <template v-if="!isTablet">
            <td class="text-start">
              <span class="text-sm text-regular">
                {{ item.salesId }}
              </span>
            </td>
            <td class="text-start">
              <span class="col-name text-sm text-medium">
                {{ item.name }}
              </span>
            </td>
            <td class="text-start">
              <span class="text-sm text-regular">
                {{ item.orderDate }}
              </span>
            </td>
            <td class="text-start">
              <BaseChip
                size="small"
                :color="getTicketPurchaseHistoryStatusColor(item.status)"
              >
                <span>{{ getTicketPurchaseHistoryStatusName(item.status) }}</span>
              </BaseChip>
            </td>
            <td class="text-end">
              <span class="text-sm text-regular">
                {{ formatCurrency(item.fullAmount) }}
              </span>
            </td>
            <td class="text-end">
              <RouterLink
                :to="{
                  name: ROUTE.RECEIPT.NAME,
                  params: {
                    id: item.salesId,
                  },
                }"
                target="_blank"
              >
                <BaseButton
                  :size="BtnSize[0]"
                  :variant="BtnVariant[1]"
                  :color="BtnColor[1]"
                >
                  <template #leftIcon>
                    <PrinterIcon />
                  </template>
                  Receipt
                </BaseButton>
              </RouterLink>
            </td>
          </template>
          <template v-else>
            <td class="text-start">
              <div class="event-wrapper">
                <p class="col-name text-sm text-medium">
                  {{ item.name }}
                </p>
                <div class="text-xs text-regular">
                  <span>
                    {{ item.salesId }}
                  </span>
                  <span class="divider">
                    /
                  </span>
                  <span>
                    {{ item.orderDate }}
                  </span>
                </div>
                <BaseChip
                  size="small"
                  :color="getTicketPurchaseHistoryStatusColor(item.status)"
                >
                  {{ getTicketPurchaseHistoryStatusName(item.status) }}
                </BaseChip>
              </div>
            </td>
            <td class="text-end">
              <div class="amount-wrapper">
                <span class="text-sm text-regular">
                  {{ formatCurrency(item.fullAmount) }}
                </span>
                <RouterLink
                  :to="{
                    name: ROUTE.RECEIPT.NAME,
                    params: {
                      id: item.salesId,
                    },
                  }"
                  target="_blank"
                >
                  <BaseButton
                    :size="BtnSize[0]"
                    :variant="BtnVariant[1]"
                    :color="BtnColor[1]"
                    icon-only
                  >
                    <PrinterIcon />
                  </BaseButton>
                </RouterLink>
              </div>
            </td>
          </template>
        </tr>
      </template>
      <template #expanded-row="{ columns, item }">
        <tr
          v-if="item.orderHistories.length > 0"
          class="expanded-sub-row"
        >
          <td :colspan="columns.length">
            <div
              class="wrapper"
            >
              <div
                v-for="(order, orderIndex) in item.orderHistories"
                :key="`${order.attendeeId}-${orderIndex}`"
                class="row"
              >
                <template v-if="!isTablet">
                  <div
                    v-for="(column, colIndex) in columns"
                    :key="`${column.key}-${colIndex}`"
                    :class="['column text-sm text-regular',
                             {
                               'text-end': column.key === TICKET_PURCHASE_HISTORY_COLUMN_KEY.AMOUNT,
                               'sub-col-name': column.key === TICKET_PURCHASE_HISTORY_COLUMN_KEY.NAME
                             }
                    ]"
                  >
                    <span v-if="column.key === TICKET_PURCHASE_HISTORY_COLUMN_KEY.DATE">
                      {{ order.updated }}
                    </span>
                    <BaseChip
                      v-if="column.key === TICKET_PURCHASE_HISTORY_COLUMN_KEY.STATUS"
                      size="small"
                      :color="getTicketOrderHistoryStatusColor(order.status, order.totalAmount)"
                    >
                      {{ getTicketOrderHistoryStatusName(order.status, order.totalAmount) }}
                    </BaseChip>
                    <span v-if="column.key === TICKET_PURCHASE_HISTORY_COLUMN_KEY.AMOUNT">
                      <span :class="{'col-refunded': isRefunded(order.status, order.totalAmount) }">{{ getTicketOrderHistoryAmount(order.status, order.totalAmount) }}</span>
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div class="first-column text-sm text-regular">
                    <p>
                      {{ order.updated }}
                    </p>
                    <BaseChip
                      size="small"
                      :color="getTicketOrderHistoryStatusColor(order.status, order.totalAmount)"
                    >
                      {{ getTicketOrderHistoryStatusName(order.status, order.totalAmount) }}
                    </BaseChip>
                  </div>
                  <div class="last-column text-sm text-regular text-end">
                    <span :class="{'col-refunded': isRefunded(order.status, order.totalAmount) }">{{ getTicketOrderHistoryAmount(order.status, order.totalAmount) }}</span>
                  </div>
                </template>
              </div>
              <!-- Summary amount row -->
              <div class="row">
                <template v-if="!isTablet">
                  <div
                    v-for="count in headers.length"
                    :key="count"
                    :class="[{'text-sm text-semibold text-end': count === 5}]"
                  >
                    <template
                      v-if="count === 5"
                    >
                      {{ formatCurrency(item.amount) }}
                    </template>
                  </div>
                </template>
                <template v-if="isTablet">
                  <div />
                  <div class="last-column text-sm text-semibold text-end">
                    {{ formatCurrency(item.amount) }}
                  </div>
                </template>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #bottom>
        <BasePagination
          v-if="dataItems?.length"
          :page="paginationInfo?.currentPage"
          :total-pages="paginationInfo?.totalPages"
          @on-page-change="handlePageChange"
        />
      </template>
    </VDataTable>
  </div>
</template>
<style scoped lang="scss">
.amount-wrapper {
  display: inline-flex;
  gap: spacings-get(4);
  height: 100%;
}

.event-wrapper {
  display: grid;
  gap: spacings-get(2);

  .divider {
    color: colors-get(gray, 400);
  }
}

.expanded-sub-row {
  border-bottom: 1px solid #{colors-get(gray, 200)};

  td {
    padding: 0 !important;
  }

  .wrapper {
    display: grid;
    gap: spacings-get(4);
    padding: spacings-get(4) 0;
    margin-bottom: spacings-get(4);
    background-color: colors-get(gray, 25);
    @include border-radius-default;

    @include media-query-max(tablet) {
      padding-left: spacings-get(2);
      }

    .row {
      display: grid;
      grid-template-columns: rem(90) minmax(160px, auto) rem(130) rem(120) rem(120) rem(160);

      @include media-query-max(tablet) {
        grid-template-columns: auto rem(120);
      }

      .column {
        padding-right: spacings-get(6);

        &.text-end {
          padding-right: 0;
        }
      }


      .first-column {
        display: grid;
        gap: spacings-get(2);
      }

      .last-column {
        padding-right: rem(54);
      }

    }
  }

}

.col-name {
  color: colors-get(gray, 900);
}

.col-refunded {
  color: colors-get(primary, 700);
}

.table-loader-row {
  display: grid;
  grid-template-columns: rem(90) minmax(160px, auto) rem(130) rem(120) rem(120) rem(160);
  align-items: center;
  gap: spacings-get(4);

  @include media-query-max(tablet) {
    padding: spacings-get(4) 0;
    grid-template-columns: auto rem(80) rem(40);
    align-items: start;
  }

  .btn-loader {
    margin: spacings-get(4) spacings-get(6);

    @include media-query-max(tablet) {
      margin: 0;
    }
  }
}

@include media-query-max(tablet){
  :deep(.v-table.v-data-table .v-table__wrapper .sort-column-wrapper .sort-header) {
    display: flex;
  }
}
</style>