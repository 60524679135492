// Define constants for ticket price calculation fees
export const PLATFORM_FEE_RATE = 0.05; // 5%
export const PROCESSING_FEE_RATE = 0.029; // 2.9%
export const PROCESSING_FEE_ADDITIONAL = 0.30; // $0.30

// Maximum file size that allow to upload
export const MAXIMUM_FILE_SIZE = 5242880;

export const TICKET_PURCHASE_LIMIT = 8;
export const TOKEN_GATE_PURCHASE_LIMIT = 1;

export const DEFAULT_COUNTRY = 840;
export const DEFAULT_TIMEZONE = 'America/New_York';

export const PAGINATION_CONFIG = {
	DEFAULT_PAGE: 1,
	ITEM_PER_PAGE: 10,
};

export const PEAZ_STANDARD = 31;