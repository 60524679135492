import type { NotificationProps } from '@/types/notification';

interface NotificationStore {
	message: NotificationProps
}

export const useNotificationStore = defineStore('notification', {
	state: (): NotificationStore => ({
		message: {
			header: '',
			content: '',
			type: NOTIFICATION_TYPE.SUCCESS,
			isShow: false,
			isShowDismiss: false,
			link: null,
		},
	}),

	actions: {
		showNotification(data: NotificationProps) {
			this.message = data;
		},

		hideNotification() {
			this.message = {
				...this.message,
				isShow: false,
			};
		},

		showSuccessNotification(
			title: string,
			message: string = '',
			isShowDismiss: boolean = false,
			link: string | null = null,
		) {
			this.showNotification({
				header: title,
				content: message,
				type: NOTIFICATION_TYPE.SUCCESS,
				isShow: true,
				isShowDismiss,
				link,
			});
		},

		showErrorNotification(
			title: string,
			message: string = '',
			isShowDismiss: boolean = false,
			link: string | null = null,
		) {
			this.showNotification({
				header: title,
				content: message,
				type: NOTIFICATION_TYPE.ERROR,
				isShow: true,
				isShowDismiss,
				link,
			});
		},

		showWarningNotification(
			title: string,
			message: string = '',
			isShowDismiss: boolean = false,
			link: string | null = null,
		) {
			this.showNotification({
				header: title,
				content: message,
				type: NOTIFICATION_TYPE.WARNING,
				isShow: true,
				isShowDismiss,
				link,
			});
		},

		showInfoNotification(
			title: string,
			message: string = '',
			isShowDismiss: boolean = false,
			link: string | null = null,
		) {
			this.showNotification({
				header: title,
				content: message,
				type: NOTIFICATION_TYPE.INFO,
				isShow: true,
				isShowDismiss,
				link,
			});
		},
	},
});
