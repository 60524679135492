<script setup lang="ts">
import type { LocationCoordinate } from '@/types/location';
import { GoogleMap, Marker } from 'vue3-google-map';

const props = defineProps({
	coordinate: {
		type: Object as PropType<LocationCoordinate>,
		required: true,
	},
	height: {
		type: String,
		default: '500',
	},
});
const apiKey = import.meta.env.VITE_GOOGLE_MAP_API_KEY;
const mapTypeControlOptions = { position: google.maps.ControlPosition.RIGHT_TOP };
const center = computed(() => {
	// Google Map need default lat, lng. We cannot leave it null
	return {
		lat: props.coordinate?.lat || 40.689247,
		lng: props.coordinate?.lng || -74.044502,
	};
});

const showMap = computed(() => {
	return !!props.coordinate?.lat && !!props.coordinate?.lng;
});
</script>
<template>
  <GoogleMap
    v-if="showMap"
    class="map"
    :api-key="apiKey"
    :style="`width: 100%; height: ${height}px`"
    :center="center"
    :fullscreen-control="false"
    :street-view-control="false"
    :map-type-control-options="mapTypeControlOptions"
    :zoom="15"
  >
    <Marker :options="{ position: center }" />
  </GoogleMap>
</template>
<style scoped lang="scss">
.map {
  border-radius: 8px;
  overflow: hidden;
}
</style>