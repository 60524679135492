export enum EVENT_TYPE {
	PHYSICAL = 'physical',
	ONLINE = 'online',
}

export enum EVENT_STATUS {
	DRAFT = 'draft',
	OPEN = 'open',
	FINISHED = 'finished',
	CANCELED = 'canceled',
	DELETED = 'deleted',
	SOLD_OUT = 'sold_out',
}

export enum EVENT_TICKET_STATUS {
	ON_SALE = 'on_sale',
	ON_HOLD = 'on_hold',
	DELETED = 'deleted',
	SOLD_OUT = 'sold_out',
	SUSPENDED = 'suspended',
	OPEN = 'open',
}

export enum EVENT_MODAL_NAME {
	EDIT = 'edit',
	PAUSE = 'pause',
	RESUME = 'resume',
	DELETE = 'delete',
}

export enum EVENT_CALENDAR_TYPE {
	GOOGLE = 'Google',
	ICAL = 'iCal',
}